import React from 'react'
import Slider from "react-slick";
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'



class Banner extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">Localbitcoins</span> Clone Script
                </h1>
                <p className="sub-heading">Kickstart your dream P2P exchange business like LocalBitcoins instantly
                </p>
                <p>Use our LocalBitcoins clone script to create your own P2P crypto exchange which is highly responsive with a faster trading engine. Initiate your peer-to-peer crypto exchange with the Escrow-powered LocalBitcoins clone and witness the benefits thoroughly.</p>
                <div className="banner-btn">
                  <ButtonStartfree />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
              <div className="screen">
                <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/laptop-outer.webp" alt="Localbitcoins clone laptop front view image1" />
                <div className="outder">
                  <Slider {...settings}>
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/localbitcoins-clone-script.webp" alt="Localbitcoins clone script software image2" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/localbitcons-clone.webp" alt="Localbitcoins clone script image3" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/localbitcoins.webp" alt="Localbitcoins clone image4" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner