import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span> Our Localbitcoins Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  User Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Admin Portal
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Custom UI/UX
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  KYC/AML Validation
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Real Time Trade Statistics
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Multilingual
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Online/Offline Trading
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Trade Chat
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Dispute Management
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Content Management System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab11')} >
                  Admin profit management
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab12')} >
                  Support tickets
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">User Dashboard
                        </h4>
                        <p className="pharagraph">Our Localbitcoins clone software has an astonishing user dashboard with real time live price of cryptocurrencies and the total user BUY/SELL advertisements.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="400px" height="270px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/user-dashboard.webp" alt="User Dashboard Image1" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Portal
                        </h4>
                        <p className="pharagraph">We provide a good enough admin panel for the exchange owner to manage the entire
                          system such as total KYC verified users, pending withdrawal's, completed trades, total
                          transaction details, deposit, trade and withdrawal commission and more.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="432px" height="281px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/admin-portal.webp" alt="Admin Portal Image1" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Custom UI/UX
                        </h4>
                        <p className="pharagraph">We ensure to provide a muscular and tradition user interface which makes the user
                          connect with the exchange in real time experience.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="300px" height="279px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/custom-ui-ux.webp" alt="Custom UI/UX Image1" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">KYC/AML Validation
                        </h4>
                        <p className="pharagraph">Our software will be compliant with KYC/AML for user identification. The regulations will
                          be applied to all major countries. The users who are registering on your exchange will be
                          verified and authenticated through their given KYC information.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="397px" height="297px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/kyc-aml-validation.webp" alt="KYC/AML Validation Image1" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Real Time Trade Statistics
                        </h4>
                        <p className="pharagraph">Users can view the real time crypto trading statistics in our localbitcoins clone dashboard
                          which helps them to keep track of the current crypto price in the market. Also, helps to
                          make decisions before beginning the crypto trading process.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="400px" height="277px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/real-time-trade-statistics.webp" alt="Real Time Trade Statistics Image1" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multilingual
                        </h4>
                        <p className="pharagraph">Our clone script offers extreme comfortness while communicating in the native language.
                          This will quickly solve user queries at a faster rate.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw350" width="293px" height="267px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/multilingual.webp" alt="Multilingual Image1" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Online/Offline Trading
                        </h4>
                        <p className="pharagraph">In online trade, the buyer and seller meet online at any time, anywhere. The offline
                          trading meets face to face with the buyer/seller. They meet in a public place and
                          exchange the money or any type of payment.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="321px" height="293px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/online-offline-trading.webp" alt="Online/Offline Trading Image1" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Trade Chat
                        </h4>
                        <p className="pharagraph">In our Localbitcoins clone script, users can communicate between them during trade and
                          also they can upload the proof of payment. In the chat box, an unique trade number will
                          be generated for admin validation.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="397px" height="265px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/trade-chat.webp" alt="Trade Chat" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Dispute Management
                        </h4>
                        <p className="pharagraph">When a buyer/seller are in disagreement over a trade either third party can raise a
                          dispute to intimate a mediation process on the crypto held in escrow.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="328px" height="294px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/dispute-management.webp" alt="Dispute Management" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Content Management System
                        </h4>
                        <p className="pharagraph">Content Management System allows users to collaborate in the creation, editing and
                          production of digital content such as web pages, blog posts etc.,
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="318px" height="270px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/content-management-system.webp" alt="Content Management System" />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin profit management
                        </h4>
                        <p className="pharagraph">Our exchange software comes with a transparent profit management system for Admin where the commissions would be calculated and displayed.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="294px" height="290px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/admin-profit-management.webp" alt="Admin profit management" />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Support tickets
                        </h4>
                        <p className="pharagraph">Support system helps your users to raise their complaints and queries directly with the admin. Once the complaint is solved, the admin can close the ticket.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="293px" height="267px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/support-ticket.webp" alt="Support tickets" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures