import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
              Localbitcoins Clone Script Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Affiliate System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  API Documentation
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Security Bounties
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Liquidity API Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Blogs and Announcements
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4"> Affiliate System
                      </h4>
                      <p className="pharagraph">If earning passive income is something you desire, then this program is exactly what you
                        need to earn consistent income on the go. Admin can offer different levels of referral
                        bonus to all registered traders.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" > 
                      <img width="376px" height="329px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/affiliate-system.webp" alt="Affiliate System image1" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">API Documentation
                      </h4>
                      <p className="pharagraph">Our localbitcoins clone script API is created for programmers who want to connect the
                        documentations to their exchanges such as live market data, Liquidity, trade volumes
                        and so on.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="427px" height="231px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/api-documentation.webp" alt="API Documentation image1" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Security Bounties
                      </h4>
                      <p className="pharagraph">The exchange owner can provide rewards for the security researchers for bug reports
                        that helps to improve the exchange security.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="427px" height="284px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/security-bounties.webp" alt="Security Bounties image1" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Liquidity API Integration
                      </h4>
                      <p className="pharagraph">Connecting API from top exchanges will increase liquidity in your exchange which results in gaining users to your newly started exchange platform quickly. 
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="499px" height="258px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/liquidity-api-integration.webp" alt="Liquidity API Integration image1" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Blogs and Announcements
                      </h4>
                      <p className="pharagraph">Engage your users by posting latest news, articles related to crypto markets. Also as an admin you can notify users with important announcements such as a new feature integration, upcoming server maintenance and much more.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="325px" height="266px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/blogs-and-announcements.webp" alt="Blogs and Announcements image1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon