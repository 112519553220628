import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of our</span>
              Localbitcoins Clone Script</h3>
            <p className="pharagraph head">Coinsclone follows the below-mentioned steps for delivering a premium Localbitcoins clone script.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
        <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/requirment-and-planning.png" alt="Development and Approach image1" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirement Gathering</h5>
            <p className="pharagraph" >Our team of experts will gather and analyze the client requirements to provide the ultimate solution for creating the p2p crypto exchange like Localbitcoins.
            </p>
            <br />
            <h5 >Precise Planning </h5>
            <p className="pharagraph" >Proper planning is more important in the LocalBitcoins clone software development process. We would get the resources and plan them accordingly.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p className="pharagraph" >Designing is also one of the important sections in the development process. Our well-skilled designers will design the software as per the client’s business concepts.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">In this stage, we develop and customize Localbitcoins clone software with outstanding trading features and high-level security modules based on client needs.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/design-development.png" alt="Designing and development image1" />
          </div>
        </div>
        <div className="row table-content orderflex mb-0 order1">
        <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/testing-and-deployment.png" alt="Testing and deployment image1" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2 mb-0">
            <h5 >Testing</h5>
            <p  className="pharagraph">Each stage of the software development will move to the next level with the testing procedure to deliver a lag-free p2p crypto exchange like Localbitcoins.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph mb-0">Finally, we deploy a superfine crypto exchange similar to Localbitcoins on the server as per the client demand.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach