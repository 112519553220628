import React from 'react'




class Whatislocal extends React.Component {



  render() {


    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg transp'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
                LocalBitcoins Clone Script?</h2>
              </div>
            </div>

            <div className="text-center">
              <p className="pharagraph" >LocalBitcoins clone script is a pre-made, multi-tested P2P exchange software that helps entrepreneurs build a P2P crypto exchange similar to LocalBitcoins instantly. This clone script functions similarly to the LocalBitcoins and has all the current features & plug-ins of LocalBitcoins. The LocalBitcoins clone software is completely customizable and hence you can customize the software with some additional features and other add-ons according to your business requirements.
              </p>
              <p className="pharagraph mb-0">This clone script comes with the core features like ads-based trading, P2P trading, escrow wallet, and more. These features make the users trade a variety of crypto coins in a hassle-free manner which makes it a ready-to-deploy P2P crypto exchange solution. It offers multiple payment methods so that your user will find it easy to trade crypto to fiat or vice versa.
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatislocal