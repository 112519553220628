import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span> Coinsclone For Localbitcoins Clone Script?</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img width="581px" height="492px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/why-choose-coinsclone-for-localbitcoins-clone-script.webp" alt="Localbitcoins clone script software provider image1" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is the leading <span className='learn-more'><a href='https://www.coinsclone.com/cryptocurrency-exchange-development-company/'>cryptocurrency exchange development company</a></span> in the global crypto marketplace. Being a leading provider in the crypto sector, we provide all kinds of customizable crypto exchange clone script solutions to our reputed clients and our script is bug-free. We have an experienced team of developers who are experts in developing and delivering the secure p2p crypto exchange platform similar to Localbitcoins based on client satisfaction within the cut-off time. 
              </p>
              <p className="pharagraph">We, at Coinsclone, use new-version technology tools to craft the updated Localbitcoins clone software. We provide complete Admins support and security support to our script making it the most encrypted peer-to-peer crypto exchange platform like Localbitcoins. We have a dedicated team of experts for affording productive 24/7 technical and customer support through the internet. 
              </p>
              <p className="pharagraph mb-0">Check the free demo of our premium Localbitcoins clone script and Build your own p2p cryptocurrency exchange platform like Localbitcoins with Coinsclone right now!
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose