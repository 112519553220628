import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span> Our Localbitcoins Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Quick BUY/SELL</h4>
                <p className="pharagraph">Right on the front page, traders can see the list of top ranking offers of BUY/SELL and
                  choose the desired advertisements and complete their trades easier and faster.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/quick-buy-sell.webp" alt="Quick BUY/SELL Image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/escrow-system.webp" alt="Escrow System Image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Escrow System
                </h4>
                <p className="pharagraph">Our LocalBitcoins clone software is protected by escrow. When a trade is started the amount of cryptos for the trade is automatically reserved from the seller’s wallet.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Best in Class Security
                </h4>
                <p className="pharagraph">Our LocalBitcoins clone software is fully tested, verified and can build robust cryptocurrency exchanges that will last forever without giving possibilities for unprotected security attacks.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/best-in-class-security.webp" alt="Best in Class Security Image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/multi-crypto-wallets.webp" alt="Multi Crypto Wallets Image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Multi Crypto Wallets
                </h4>
                <p className="pharagraph">A Crypto wallet is the most essential feature as it helps the users to store, receive, and
                  transfer all major virtual crypto coins securely.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Eliminate Anonymity
                </h4>
                <p className="pharagraph">Buyers can choose the sellers and vice versa by checking their ratings, reviews, location and other profile details. In our LocalBitcoins clone software both buyer and seller trade after completely analyzing the opposite party and trade confidently.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/eliminate-anonymity.webp" alt="Eliminate Anonymity Image1" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures