import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>White Label P2P Exchange</span> to Build a Platform</span> Like LocalBitcoins</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-lg-7 col-md-12 order2">
              <p className="pharagraph" >White label P2P exchange is a readymade P2P clone software that aids budding entrepreneurs to build a peer-to-peer cryptocurrency exchange platform. The software finally resulted in enveloping similar functions to the LocalBitcoins Exchange Platform and proved to be a sensation so far in the Crypto market. We, at Coinsclone have ensured that the customizable white label P2P exchange is developed on the latest advanced technologies.
              </p>
              <p className="pharagraph mb-0" >Our white label peer-to-peer Crypto exchange is a game-changing development solution and makes the process of building an Ads-based exchange simple and effective. We take the privilege of providing the best P2P exchange development solution in the market with unwavering results continuously. By opting for Coinsclone’s White label P2P cryptocurrency exchange, you as an entrepreneur can get a plethora of significant benefits. Safety and provision are two areas we focus on to bring you a terrific, secure, and multi-tested P2P platform.
              </p>
            </div>
            <div className="col-lg-5 col-md-12 left-side order1">
              <img width="465px" height="421px" src="https://coinsclone.mo.cloudinary.net/images/localbit-new/white-label-p2-p-exchange-to-build-a-platform.webp" alt="P2P Exchange like Localbitcoins Clone Script Image1" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis