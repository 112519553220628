import React from 'react'


const SecurityofOur = () => {

  return (
    <section className="security pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Security Features</span> of </span>
              Our Localbitcoins Clone Software</h3>
            <p className="pharagraph head">We at coinsclone integrate cutting-edge security features in our software to avoid hacking and other malware activities.</p>
          </div>
        </div>
        <div className="binanceflexbox mt-0" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.985" height="34.515" viewBox="0 0 31.985 34.515">
                  <g id="_2FA" data-name="2FA" transform="translate(0)">
                    <path id="Path_5209" data-name="Path 5209" d="M33.332,0c.559.088,1.132.148,1.685.263A13.762,13.762,0,0,1,45.9,11.552a8.973,8.973,0,0,1,.135,1.274c.02,1.112.007,2.224.007,3.377a10.665,10.665,0,0,0-7.535-.054V13.877a6.564,6.564,0,0,0-.66-2.925A6.1,6.1,0,0,0,26.511,12.1a7.374,7.374,0,0,0-.229,1.624c-.034,1.334-.013,2.676-.007,4.01,0,.067.013.128.02.209h2v-.4c.007-1.4-.034-2.79.04-4.179a4.063,4.063,0,0,1,8.1-.007c.067,1.206.034,2.426.034,3.639a.479.479,0,0,1-.168.3c-.674.607-1.348,1.213-2.035,1.806a.516.516,0,0,1-.377.115.93.93,0,0,1-.775-1.024c-.007-1.6,0-3.215,0-4.819v-.391H31.128a.579.579,0,0,0-.027.142c0,1.766-.02,3.525.013,5.291a2.841,2.841,0,0,0,1.624,2.534.609.609,0,0,1,.108.067c-.377,1.126-.741,2.224-1.126,3.37a3.91,3.91,0,0,1-2.4-1.348,4.006,4.006,0,0,1-1.011-2.655H26.3a4.977,4.977,0,0,0,.27,1.813,6.008,6.008,0,0,0,4.83,4.188c.256.047.3.148.337.377.135.829.31,1.651.479,2.473a4.109,4.109,0,0,0,.135.411,7.878,7.878,0,0,1-2.709-.438,8.687,8.687,0,0,1-5.553-5.553,8.516,8.516,0,0,1-.452-2.878c0-2.379-.007-4.765,0-7.144a8.759,8.759,0,1,1,17.514-.383c0,.067.006.128.012.209h2.022c-.034-.452-.04-.91-.1-1.355A10.63,10.63,0,0,0,39.33,5.237a10.382,10.382,0,0,0-5.291-2.406,10.787,10.787,0,0,0-12.151,8.236,12.645,12.645,0,0,0-.283,2.568c-.034,2.379-.02,4.765-.013,7.144a10.605,10.605,0,0,0,3.9,8.418,10.413,10.413,0,0,0,7.05,2.487,3.14,3.14,0,0,0,.438-.013.545.545,0,0,1,.553.263c.559.674,1.152,1.314,1.732,1.968a1.5,1.5,0,0,0,.222.189c-.013.034-.02.061-.034.094l-1.779.263c-.121.02-.236.047-.357.067H31.431a1.763,1.763,0,0,0-.256-.061,13.164,13.164,0,0,1-7.5-3.093A13.509,13.509,0,0,1,18.707,20.67c-.007-2.291-.013-4.583.007-6.874a15.109,15.109,0,0,1,.162-2.211,13.4,13.4,0,0,1,3.6-7.305A13.562,13.562,0,0,1,29.76.263C30.319.148,30.885.088,31.445,0Z" transform="translate(-18.7)" fill="gray" />
                    <path id="Path_5210" data-name="Path 5210" d="M249.319,276.373c-.505-.088-1.024-.128-1.523-.263a8.311,8.311,0,0,1-6.288-6.308,8.419,8.419,0,0,1,3.727-9.1,8.209,8.209,0,0,1,9.186.094,8.072,8.072,0,0,1,3.727,5.789,8.465,8.465,0,0,1-5,9.065,8.2,8.2,0,0,1-2.628.667,2.806,2.806,0,0,0-.33.054Zm3.72-10.554a3.615,3.615,0,0,0-.762-2.662,3.2,3.2,0,0,0-2.5-1.186,3.093,3.093,0,0,0-2.049.728,3.464,3.464,0,0,0-1.24,3.12c-.216.013-.391.027-.566.034v6.9h7.69v-6.908C253.437,265.846,253.275,265.833,253.039,265.819Z" transform="translate(-226.263 -241.867)" fill="#b0b0b0" />
                    <path id="Path_5211" data-name="Path 5211" d="M340.3,389.037V386.2h3.646v2.837Z" transform="translate(-318.625 -360.172)" fill="#b0b0b0" />
                    <path id="Path_5212" data-name="Path 5212" d="M351.182,330.168h-2.46a1.525,1.525,0,0,1,.5-1.577,1.187,1.187,0,0,1,1.361-.074C351.222,328.881,351.256,329.488,351.182,330.168Z" transform="translate(-326.454 -306.209)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Two-factor
                  Authentication</h5> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="36.702" height="36.699" viewBox="0 0 36.702 36.699">
                  <g id="Escrow_protection" data-name="Escrow protection" transform="translate(0.004)">
                    <path id="Path_8930" data-name="Path 8930" d="M847.969,461.654c-.836-.4-1.7-.752-2.5-1.22a17.952,17.952,0,0,1-9.113-13.731,18.332,18.332,0,0,1-.134-2.286c-.018-2.042-.006-4.085-.006-6.128v-.456a13.913,13.913,0,0,0,11.824-4.746,14.482,14.482,0,0,0,5.351,3.812,15.232,15.232,0,0,0,6.478.942v6.648a18.223,18.223,0,0,1-11.47,17.024c-.1.04-.189.094-.284.142Zm-7.494-19.693c.043,1.418-.025,2.826.147,4.2a13.587,13.587,0,0,0,7.189,10.664.513.513,0,0,0,.444.008,24.383,24.383,0,0,0,2.711-1.916c3.868-3.477,4.972-7.958,4.543-12.968a1.512,1.512,0,0,0-.178-.052,18.082,18.082,0,0,1-7.092-2.914c-.185-.127-.292-.052-.441.033-.921.525-1.826,1.084-2.777,1.551A17.5,17.5,0,0,1,840.475,441.961Z" transform="translate(-829.692 -424.956)" fill="gray" />
                    <path id="Path_8931" data-name="Path 8931" d="M1014.27,399.763a2.87,2.87,0,0,0-.3.231q-1.537,1.529-3.066,3.066a2.357,2.357,0,0,0-.217.287l-1.537-1.539.967-.926-.045-.116h-.357q-3.279,0-6.558-.007a.551.551,0,0,1-.412-.169c-1.014-1.659-2.01-3.33-3.029-5.029l1.84-1.107.536.891c.608,1.013,1.212,2.029,1.832,3.034a.5.5,0,0,0,.343.226c1.9.014,3.8.009,5.7.008a.475.475,0,0,0,.1-.025l-.969-.929,1.573-1.561c.022.021.116.107.206.2l3.093,3.09a3.056,3.056,0,0,0,.3.233Z" transform="translate(-977.572 -390.015)" fill="gray" />
                    <path id="Path_8932" data-name="Path 8932" d="M768,399.614a2.339,2.339,0,0,0,.272-.208q1.546-1.569,3.086-3.143c.074-.076.154-.146.2-.193l1.517,1.514-1.077,1c.222.017.32.031.419.031q2.741,0,5.48.007a.477.477,0,0,0,.479-.269c.763-1.294,1.54-2.579,2.331-3.9l1.832,1.1c-.05.1-.089.177-.135.255-.938,1.566-1.88,3.128-2.81,4.7a.478.478,0,0,1-.478.269c-2.2-.01-4.393-.006-6.59-.006h-.456l1.05.994-1.562,1.547c-.048-.046-.141-.129-.229-.218l-3.086-3.144a1.883,1.883,0,0,0-.247-.182Z" transform="translate(-768 -390.009)" fill="gray" />
                    <path id="Path_8933" data-name="Path 8933" d="M914.68,348l1.807,1.124-1.506,2.506-1.841-1.1c.208-.347.411-.678.6-1.014.291-.5.575-1.008.863-1.512Z" transform="translate(-899.269 -348)" fill="gray" />
                    <path id="Path_8934" data-name="Path 8934" d="M974.094,348c.148.294.287.594.447.882.3.55.622,1.1.945,1.662l-1.865,1.065-1.445-2.535L973.95,348Z" transform="translate(-952.662 -348)" fill="gray" />
                    <path id="Path_8935" data-name="Path 8935" d="M914.013,523.193a11.879,11.879,0,0,1-5.124,10.632.369.369,0,0,1-.5.009,11.512,11.512,0,0,1-4.987-7.993c-.131-.857-.135-1.733-.2-2.617.383-.116.709-.214,1.035-.314a18.547,18.547,0,0,0,4.08-1.8.514.514,0,0,1,.611-.012,19.327,19.327,0,0,0,5.085,2.095Z" transform="translate(-890.282 -504.466)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Escrow Protection</h5>
              </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="CSRF_protection" data-name="CSRF protection" xmlns="http://www.w3.org/2000/svg" width="37.377" height="30.898" viewBox="0 0 37.377 30.898">
                  <path id="Path_8921" data-name="Path 8921" d="M741.377,467.563v19.273a2.183,2.183,0,0,1-2.385,1.464q-16.394-.033-32.789-.013c-.134,0-.268,0-.4,0a1.821,1.821,0,0,1-1.516-.871,5.979,5.979,0,0,1-.286-.577V467.563c.121,0,.243.009.364.009h36.649C741.134,467.572,741.256,467.566,741.377,467.563Zm-18.719,16.708h2.881a1.862,1.862,0,0,0,1.89-1.827q.022-2.5,0-5a1.529,1.529,0,0,0-.875-1.492.868.868,0,0,1-.58-1.085.762.762,0,0,0,0-.109,3.284,3.284,0,0,0-5.869-1.9,3.593,3.593,0,0,0-.7,2.5c.008.166.024.306-.205.4a1.815,1.815,0,0,0-1.253,1.854q0,2.316,0,4.632a1.88,1.88,0,0,0,2.018,2.032C720.859,484.275,721.759,484.271,722.659,484.271Z" transform="translate(-704 -457.402)" fill="gray" />
                  <path id="Path_8922" data-name="Path 8922" d="M741.377,329.853v6.5c-.121,0-.243.009-.364.009H704.364c-.121,0-.243-.006-.364-.009v-6.5a2.133,2.133,0,0,1,2.339-1.473q16.423.034,32.845.013c.134,0,.268-.006.4,0a1.815,1.815,0,0,1,1.647,1.133C741.282,329.636,741.329,329.745,741.377,329.853ZM732,331.289h-2.115c-1.094,0-2.188-.009-3.282.008a1.08,1.08,0,0,0-.36,2.091,2.007,2.007,0,0,0,.644.087q5.106.008,10.212,0a2.319,2.319,0,0,0,.47-.039,1.024,1.024,0,0,0,.812-.751,1.08,1.08,0,0,0-1.129-1.4C735.5,331.284,733.753,331.289,732,331.289Zm-22.016,1.11a1.564,1.564,0,1,0-1.564,1.542A1.57,1.57,0,0,0,709.986,332.4Zm4.536-.015a1.559,1.559,0,0,0-1.569-1.572,1.564,1.564,0,1,0,.025,3.129A1.553,1.553,0,0,0,714.522,332.384Z" transform="translate(-704 -328.38)" fill="gray" />
                  <path id="Path_8923" data-name="Path 8923" d="M925.413,608.5h5.058v4.206h-5.058Z" transform="translate(-909.249 -588.051)" fill="#b0b0b0" />
                  <path id="Path_8924" data-name="Path 8924" d="M947.157,554.589h-2.178a8.254,8.254,0,0,1,.006-.913,1.092,1.092,0,0,1,2.168.053C947.176,554,947.157,554.282,947.157,554.589Z" transform="translate(-927.377 -536.361)" fill="#b0b0b0" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">CSRF Protection</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.864" height="33.86" viewBox="0 0 33.864 33.86">
                  <g id="Anti-distributed_Denial_of_Service" data-name="Anti-distributed  Denial of Service" transform="translate(-0.001 -0.003)">
                    <path id="Path_9273" data-name="Path 9273" d="M64,171.091a1.256,1.256,0,0,1,1.3-.736q8.8.022,17.593.009h.4v4.63H69.058a.991.991,0,0,0-.951,1.49,1.012,1.012,0,0,0,.945.5H83.31a3.812,3.812,0,0,0,.6,2.646H69.179a2.179,2.179,0,0,0-.4.017.991.991,0,0,0,.12,1.964c.1.007.2,0,.3,0H85.9a1.114,1.114,0,0,0,1.2-.678.3.3,0,0,1,.232-.115c1.245-.007,2.49-.005,3.786-.005v8.859c0,.822-.37,1.2-1.185,1.2H69.726a.768.768,0,0,0-.6.249q-1.531,1.555-3.087,3.087c-.239.235-.519.427-.781.639h-.529A1.5,1.5,0,0,1,64,194.11Zm13.523,15.147h8.591a.989.989,0,0,0,.253-1.955,1.965,1.965,0,0,0-.427-.026H68.987a.993.993,0,1,0,.075,1.982q4.229,0,8.46,0Z" transform="translate(-63.999 -160.979)" fill="gray" />
                    <path id="Path_9274" data-name="Path 9274" d="M64,67.211a7.779,7.779,0,0,1,.484-1.319,3.621,3.621,0,0,1,6.541.491.4.4,0,0,0,.447.305c3.527-.009,7.053,0,10.58-.009a1.029,1.029,0,0,1,1.029.574.983.983,0,0,1-.851,1.408c-.328.017-.658,0-1.034,0,0,.588.012,1.157,0,1.725a.981.981,0,0,1-1.405.889.966.966,0,0,1-.574-.928c0-.549,0-1.1,0-1.665H77.889v.7c0,.8-.358,1.257-.984,1.263s-1-.458-1-1.246v-.712c-.1-.009-.183-.024-.267-.024H71.406a.312.312,0,0,0-.354.242,3.628,3.628,0,0,1-7-.65A.545.545,0,0,0,64,68.137Z" transform="translate(-63.999 -64.044)" fill="#b0b0b0" />
                    <path id="Path_9275" data-name="Path 9275" d="M314.713,71.679a26.677,26.677,0,0,0-.023-3.116,2.891,2.891,0,0,0-3.14-2.5,2.99,2.99,0,0,0-2.744,2.889.995.995,0,1,1-1.986.03,4.961,4.961,0,1,1,9.921.147c0,.836,0,1.672,0,2.5.039.025.056.045.074.046.971.054,1.249.351,1.249,1.338v7.738c0,.815-.374,1.185-1.2,1.185h-10.18c-.816,0-1.186-.373-1.186-1.194V72.866c0-.817.372-1.187,1.192-1.187h8.023ZM310.79,76.5c0,.55-.008,1.088,0,1.625a.99.99,0,1,0,1.979-.026c.009-.536,0-1.071,0-1.6.072-.024.1-.036.133-.043a.988.988,0,0,0-.2-1.964q-.941-.011-1.882,0a.98.98,0,0,0-1,.751C309.674,75.822,310.033,76.31,310.79,76.5Z" transform="translate(-284.199 -64.08)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Anti-Distributed
                  Denial Of Service
                </h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="32.964" height="32.97" viewBox="0 0 32.964 32.97">
                  <g id="Jail_login_guard" data-name="Jail login guard" transform="translate(-1 -1)">
                    <path id="Path_9318" data-name="Path 9318" d="M1,368H25.5v8.927c0,.138.02.276.03.413H1Zm7.752,3.713v1.9h1.88v-1.9Zm9.034.006H15.894v1.9h1.893ZM5.169,373.6H7.056v-1.874H5.169Zm7.161-1.883V373.6H14.2v-1.887Zm7.133,1.884H21.35v-1.875H19.463Z" transform="translate(0 -343.37)" fill="#b0b0b0" />
                    <path id="Path_9319" data-name="Path 9319" d="M105.122,1c.736.134,1.488.209,2.2.411a10.223,10.223,0,0,1,5.9,4.305,10.208,10.208,0,0,1,1.7,6.758,10.361,10.361,0,0,1-2.582,5.961,8.629,8.629,0,0,0-4.523-4.536,5.026,5.026,0,0,0,1.612-4.762,4.789,4.789,0,0,0-1.817-2.983,5.044,5.044,0,0,0-6.737.418,5.1,5.1,0,0,0,.257,7.321,8.725,8.725,0,0,0-4.52,4.583c-.4-.565-.795-1.053-1.115-1.585a10.09,10.09,0,0,1-1.435-6.537,10.287,10.287,0,0,1,4.853-7.736,9.993,9.993,0,0,1,4.739-1.573A1.116,1.116,0,0,0,103.835,1Z" transform="translate(-86.996)" fill="gray" />
                    <path id="Path_9320" data-name="Path 9320" d="M411,377.336c.011-.138.03-.276.03-.413V368h6.536v9.336Z" transform="translate(-383.602 -343.37)" fill="#b0b0b0" />
                    <path id="Path_9321" data-name="Path 9321" d="M159.13,225.494a6.575,6.575,0,0,1,12.532-.262.3.3,0,0,1-.126.42,10.466,10.466,0,0,1-12.3-.068C159.2,225.559,159.173,225.53,159.13,225.494Z" transform="translate(-147.949 -205.628)" fill="gray" />
                    <path id="Path_9322" data-name="Path 9322" d="M214.96,97.145a3.107,3.107,0,1,1-3.092-3.129,3.143,3.143,0,0,1,3.092,3.129Z" transform="translate(-194.37 -87.027)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Jail Login Guard</h5> </div>
            </div>
          </div>
        </div>
        <div className="binanceflexbox" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="28.212" height="33.863" viewBox="0 0 28.212 33.863">
                  <g id="SSL_integration" data-name="SSL integration" transform="translate(0 -0.002)">
                    <path id="Path_9276" data-name="Path 9276" d="M96.75,378.784c.01-.131.03-.262.03-.393v-6.419h21.38v6.807Zm9.787-4.318h-7.42v1.961h6.446a7.218,7.218,0,0,0,.761-.011.3.3,0,0,0,.2-.2C106.545,375.64,106.537,375.067,106.537,374.466Zm9.16,0H113.74V376.4h1.96Zm-6.426-.011V376.4h1.941v-1.942Z" transform="translate(-96.75 -344.919)" fill="gray" />
                    <path id="Path_9277" data-name="Path 9277" d="M97.354,277.994V271.12H118.69v6.874Zm2.314-4.392v1.948h7.4V273.6Zm12.093,1.953v-1.947h-1.944v1.947Zm2.523,0h1.955v-1.949h-1.955Z" transform="translate(-97.301 -252.976)" fill="gray" />
                    <path id="Path_9278" data-name="Path 9278" d="M284.522,71.5c0-.895.005-1.789,0-2.684a.352.352,0,0,1,.238-.375c1.815-.856,3.624-1.725,5.441-2.578a.534.534,0,0,1,.415,0q2.651,1.393,5.285,2.818a.478.478,0,0,1,.221.342c.012,1.568.022,3.137,0,4.7a7.532,7.532,0,0,1-2.259,5.082,13.093,13.093,0,0,1-3.225,2.516.44.44,0,0,1-.35.046,12.061,12.061,0,0,1-4.692-4.181,6.859,6.859,0,0,1-1.069-3.735C284.524,72.8,284.522,72.151,284.522,71.5Zm5.115,1.964-1.275-1.34-1.353,1.353,2.706,2.712,4.165-4.166-1.4-1.4Z" transform="translate(-267.923 -65.819)" fill="#b0b0b0" />
                    <path id="Path_9279" data-name="Path 9279" d="M112.114,170.169a11.475,11.475,0,0,0,4.706,6.876H97.441v-6.876Zm-4.986,4.459v-1.95H99.753v1.95Zm2.766,0h1.944v-1.942h-1.944Z" transform="translate(-97.38 -160.947)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">SSL Integration</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="30.48" height="32.761" viewBox="0 0 30.48 32.761">
                  <g id="End-to-end_encrypted_transactions" data-name="End-to-end encrypted  transactions" transform="translate(-18.894 -1)">
                    <path id="Path_9323" data-name="Path 9323" d="M60.431,1H78.986V29.528a3.149,3.149,0,0,1-.1.852,2.043,2.043,0,0,1-4-.5c-.025-.9-.009-1.791-.01-2.686,0-.879-.343-1.223-1.217-1.223H56.975v-.255q0-10.41.006-20.82A3.818,3.818,0,0,1,58.5,1.853,4.651,4.651,0,0,1,60.431,1ZM64.7,12.237c-1.031.361-1.486.878-1.495,1.786q-.023,2.527,0,5.054a1.719,1.719,0,0,0,1.743,1.79q3.022.04,6.046,0a1.693,1.693,0,0,0,1.741-1.72c.028-1.727.023-3.455,0-5.182a1.7,1.7,0,0,0-1.3-1.662c-.068-.021-.135-.046-.246-.084a12.3,12.3,0,0,0-.007-1.348,3.232,3.232,0,0,0-6.41-.067C64.715,11.268,64.726,11.74,64.7,12.237Z" transform="translate(-35.644)" fill="gray" />
                    <path id="Path_9324" data-name="Path 9324" d="M22.355,427.526a9.889,9.889,0,0,1-1.583-.632,3.777,3.777,0,0,1-1.867-3.266c-.024-.638,0-1.278,0-1.945h18.4v.367a16.405,16.405,0,0,0,.077,2.2,3.891,3.891,0,0,0,3.252,3.221.809.809,0,0,1,.145.056Z" transform="translate(0 -393.766)" fill="gray" />
                    <path id="Path_9325" data-name="Path 9325" d="M431.64,1a4.62,4.62,0,0,1,1.91.826,3.883,3.883,0,0,1,1.55,3.021c.02.67.012,1.342,0,2.013a.883.883,0,0,1-.819.941c-1.082.038-2.166.012-3.282.012V1Z" transform="translate(-385.738)" fill="gray" />
                    <path id="Path_9326" data-name="Path 9326" d="M189.943,207.147v4.739a2.392,2.392,0,0,1-.255.028h-5.145c-.179,0-.3-.007-.3-.247.009-1.459.005-2.918.006-4.378a1.214,1.214,0,0,1,.02-.145Z" transform="translate(-154.771 -192.957)" fill="#b0b0b0" />
                    <path id="Path_9327" data-name="Path 9327" d="M210.4,143.126h-2.713a9.1,9.1,0,0,1,.116-1.384,1.312,1.312,0,0,1,2.528.14,11.728,11.728,0,0,1,.069,1.244Z" transform="translate(-176.714 -130.898)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">End-To-End Encrypted
                  Transactions</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="25.79" height="33.86" viewBox="0 0 25.79 33.86">
                  <g id="HTTPS_authentication" data-name="HTTPS authentication" transform="translate(0 -0.013)">
                    <path id="Path_9271" data-name="Path 9271" d="M110.25,264.585V246.753h25.79v17.832Zm12.875-3.773,1.983-9.919-1.944-.391-1.982,9.914Zm-3.248-6.376-1.389-1.391-2.606,2.606,2.589,2.58,1.372-1.383-1.249-1.223Zm7.874,1.218-1.323,1.223,1.382,1.378,2.578-2.584-2.6-2.612L126.45,254.4Z" transform="translate(-110.25 -230.712)" fill="gray" />
                    <path id="Path_9272" data-name="Path 9272" d="M182.366,76.86H178.4V72.822a3.967,3.967,0,1,0-7.934,0q0,2.826,0,5.652v.372H166.5V72.914a7.932,7.932,0,0,1,15.731-1.583,10.923,10.923,0,0,1,.133,1.61C182.378,74.238,182.366,75.536,182.366,76.86Z" transform="translate(-161.535 -64.825)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">HTTPS Authentication</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.832" height="32.496" viewBox="0 0 33.832 32.496">
                  <g id="Browser_detection_security" data-name="Browser detection  security" transform="translate(-64.5 -72.479)">
                    <path id="Path_9328" data-name="Path 9328" d="M64.5,159.173V143.908c5.055,0,10.11-.009,15.165.011a1.482,1.482,0,0,0,1.41-.764c.624-1,1.3-1.961,1.946-2.948a.522.522,0,0,1,.5-.269q7.219.011,14.438,0h.371v18.736a2.968,2.968,0,0,1-3.063,3.067h-6.7c.044-.138.089-.249.114-.364a20.376,20.376,0,0,0,.584-2.833c.11-1.557.107-3.126.061-4.687a2.719,2.719,0,0,0-1.747-2.518,15.324,15.324,0,0,0-4.488-1.159,16.106,16.106,0,0,0-7.609,1.057,2.97,2.97,0,0,0-2,2.865c0,1.123-.05,2.25.014,3.369a29.645,29.645,0,0,0,.379,2.971,10.914,10.914,0,0,0,.373,1.3h-6.6c-.132,0-.265,0-.4-.008a2.98,2.98,0,0,1-2.629-2.128C64.582,159.467,64.542,159.32,64.5,159.173Z" transform="translate(0 -61.516)" fill="gray" />
                    <path id="Path_9329" data-name="Path 9329" d="M64.5,75.057c.05-.179.092-.36.15-.536a2.973,2.973,0,0,1,2.834-2.038c2.247-.008,4.493,0,6.74,0H95.237a2.937,2.937,0,0,1,3.057,2.576,9.83,9.83,0,0,1,.01,1.388h-.375q-7.516,0-15.033-.006a1.267,1.267,0,0,0-1.177.631c-.642,1-1.321,1.976-1.967,2.974a.708.708,0,0,1-.682.375q-7.285-.02-14.57-.009Zm4.962,2.378a.99.99,0,1,0-1-.976A1,1,0,0,0,69.462,77.435Zm4.948-.977a.99.99,0,1,0-1,.977,1,1,0,0,0,1-.977Zm2.959-1a.99.99,0,1,0,1.006.966,1,1,0,0,0-1.006-.969Z" fill="gray" />
                    <path id="Path_9330" data-name="Path 9330" d="M200.754,282.059a12.143,12.143,0,0,1-.393,3.844,10.02,10.02,0,0,1-4.914,6.071,1.2,1.2,0,0,1-1.276,0,9.9,9.9,0,0,1-5.3-8.735c-.041-1.144-.009-2.29-.006-3.435a1.006,1.006,0,0,1,.712-1.035,13.9,13.9,0,0,1,10.4-.022,1.025,1.025,0,0,1,.78,1.119C200.755,280.492,200.754,281.12,200.754,282.059Zm-6.677,3.011c-.251-.28-.455-.541-.693-.767A.985.985,0,0,0,192,285.7q.7.727,1.425,1.424a.934.934,0,0,0,1.318.039c.989-.956,1.959-1.932,2.919-2.918a.89.89,0,0,0,.159-1.018.941.941,0,0,0-.84-.6,1.035,1.035,0,0,0-.85.36c-.663.678-1.331,1.348-2.053,2.082Z" transform="translate(-113.39 -187.182)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Browser Detection
                  Security</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.813" height="33.836" viewBox="0 0 33.813 33.836">
                  <g id="Server_Side_Forgery_Protection" data-name="Server Side Forgery  Protection" transform="translate(0 0.001)">
                    <path id="Path_9284" data-name="Path 9284" d="M64.5,129.162c.083-.608.15-1.219.252-1.825a16.479,16.479,0,0,1,3.032-7.128c.045-.061.085-.124.162-.237-.162-.007-.286-.012-.409-.019a1,1,0,0,1-1.009-1,.985.985,0,0,1,1.064-.974q1.239-.009,2.478,0a.987.987,0,0,1,1.078,1.066c.01.8.005,1.608,0,2.412a1.014,1.014,0,0,1-.846,1.124c-.526.084-.936-.212-1.162-.9-.3.5-.6.944-.852,1.411a14.947,14.947,0,0,0,18.712,21,1.841,1.841,0,0,1,.792-.15.975.975,0,0,1,.175,1.881c-1.062.354-2.127.708-3.211.98a22.616,22.616,0,0,1-2.277.344H80.361c-.085-.019-.168-.043-.254-.055-.794-.117-1.6-.188-2.38-.353A16.923,16.923,0,0,1,64.76,133.168c-.1-.628-.174-1.26-.26-1.891Z" transform="translate(-64.5 -113.303)" fill="#b0b0b0" />
                    <path id="Path_9285" data-name="Path 9285" d="M128,141.94c-.218-.548-.4-1.031-.607-1.5a.36.36,0,0,0-.262-.165c-.285-.021-.572,0-.858-.009a.992.992,0,0,1-1.044-1.034q-.009-1.933,0-3.865a.994.994,0,0,1,1.064-1.047H127.3c.221-.51.44-1,.641-1.5a.3.3,0,0,0-.079-.242c-.208-.227-.437-.435-.648-.66a.972.972,0,0,1,0-1.4q1.378-1.4,2.779-2.781a.977.977,0,0,1,1.469.02c.245.237.475.489.713.735l1.643-.681v-1.048a.99.99,0,0,1,1.04-1.038q1.933-.008,3.865,0a.991.991,0,0,1,1.04,1.037c.006.352,0,.7,0,1.046l1.664.692c.216-.232.446-.5.7-.745a.982.982,0,0,1,1.492,0q1.349,1.337,2.687,2.686a1,1,0,0,1-.007,1.542c-.23.236-.589.443-.652.718-.057.25.2.576.331.864.113.25.178.6.376.714.214.125.556.03.843.03a1.02,1.02,0,0,1,1.128.757,1.331,1.331,0,0,1,.033.327v3.766a1.008,1.008,0,0,1-1.114,1.1c-.319,0-.711-.1-.936.043s-.265.549-.391.838c-.1.241-.216.479-.344.761.251.237.514.471.76.723a.981.981,0,0,1,.006,1.47q-1.358,1.375-2.733,2.733a.98.98,0,0,1-1.469,0c-.252-.246-.487-.508-.71-.743-.535.213-1.026.4-1.509.609a.329.329,0,0,0-.142.245c-.018.3,0,.594-.008.892a.988.988,0,0,1-1.027,1.018q-1.949.01-3.9,0a.989.989,0,0,1-1.021-1.024c-.008-.352.11-.779-.05-1.034-.147-.234-.592-.277-.9-.412-.669-.293-.668-.295-1.184.221-.094.093-.184.19-.283.277a.954.954,0,0,1-1.346.011q-1.457-1.416-2.875-2.871a.966.966,0,0,1,.03-1.348c.127-.138.259-.271.4-.4S127.888,142.034,128,141.94Zm14.079-4.657a5.287,5.287,0,1,0-5.3,5.295,5.287,5.287,0,0,0,5.3-5.295Z" transform="translate(-119.872 -120.374)" fill="gray" />
                    <path id="Path_9286" data-name="Path 9286" d="M195.549,92.176c.234.02.422.021.6.055a.962.962,0,0,1,.8,1.015.942.942,0,0,1-.9.924q-1.4.032-2.807,0a.922.922,0,0,1-.9-.928q-.036-1.37,0-2.741a.951.951,0,0,1,.871-.928c.511-.062.9.233,1.116.875.129-.192.249-.359.358-.533a14.941,14.941,0,0,0-18.233-21.831,1.72,1.72,0,0,1-.794.13.937.937,0,0,1-.786-.861.994.994,0,0,1,.636-1.018,15.546,15.546,0,0,1,3.858-1.106A16.928,16.928,0,0,1,195.7,91.945C195.662,92.007,195.621,92.07,195.549,92.176Z" transform="translate(-165.152 -65.018)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Server Side Forgery
                  Protection</h5>
              </div> </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecurityofOur